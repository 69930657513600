import { render, staticRenderFns } from "./JoinInfo.vue?vue&type=template&id=6f2e9328&scoped=true&"
import script from "./JoinInfo.vue?vue&type=script&lang=js&"
export * from "./JoinInfo.vue?vue&type=script&lang=js&"
import style0 from "./JoinInfo.vue?vue&type=style&index=0&id=6f2e9328&lang=scss&scoped=true&"
import style1 from "./JoinInfo.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./JoinInfo.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f2e9328",
  null
  
)

export default component.exports